import { solidstate, type _SolidState } from ":shared/utils/state-manager";
import { For, Match, Show, Switch, createMemo } from "solid-js";
import type { EvaluationModal } from "./_model";
import { global } from ":global";
//
import { Information } from "./informtion";
import { Grading } from "./grading";

const tabs = ["info", "grading"] as const;
export function EvaluationModal(props: EvaluationModal.ModalProps) {
  const active_idx = solidstate.create(props.idx);
  const entity = createMemo(() => props.data.value.teams[active_idx.value]);
  const $breakpoint = global.store.hooks.dom.useDedicatedBreakpoints("xs", "sm", "md", "lg");
  const $tab = solidstate.create(tabs[0] as (typeof tabs)[number]);
  const $grade = solidstate.create(undefined as number);

  function switchEntity(next: boolean) {
    const arr_length = props.data.value.teams.length;
    let idx = active_idx.value;
    if (next) {
      idx++;
      if (idx >= arr_length) idx = 0;
    } else {
      idx--;
      if (idx <= -1) idx = arr_length - 1;
    }
    active_idx.set(idx);
  }

  async function onSubmit(p: { scores: Record<string, number> }) {
    const scores = p.scores;
    const team = entity();
    return props.onSubmit({ team, scores }).then((new_team) => {
      global.store.actions.alert.pushToast({
        type: "success",
        message: "Evaluation Submitted!",
      });
      props.data.set((s) => {
        const teams = [...s.teams];
        let history = s.history ?? [];
        if (!history.includes(new_team.id)) {
          history = [...history, new_team.id];
        }
        teams[active_idx.value] = new_team;
        return { ...s, history, teams };
      });
    });
  }

  return (
    <Switch>
      <Match when={$breakpoint("xs", "sm")}>
        <section class="flex flex-col w-full h-full overflow-hidden">
          <div class="flex flex-row w-full h-fit items-center">
            <For each={tabs}>
              {(item, index) => {
                return (
                  <p
                    //
                    onclick={() => $tab.set(item)}
                    class={`
                      w-full capitalize text#p rounded-md text-center
                      ${$tab.value === item ? "bg#a text-white" : ""}
                    `}
                  >
                    {item}
                  </p>
                );
              }}
            </For>
          </div>
          <div class="flex flex-col w-full h-full overflow-hidden my-1">
            <Switch>
              <Match when={$tab.value === "info"}>
                <Information entity={entity()} />
              </Match>
              <Match when={$tab.value === "grading"}>
                <Grading
                  class="flex flex-col w-full h-full space-y-2 overflow-hidden"
                  data={props.data?.value}
                  entity={entity()}
                  onSubmit={onSubmit}
                  onNext={() => switchEntity(true)}
                />
              </Match>
            </Switch>
          </div>
          <div class="flex flex-row w-full h-2rem space-x-2 children:(flex flex-row w-full h-full px-2 py-2 text-0.6rem rounded-lg items-center justify-center cursor-pointer bg#p text#page)">
            <button onClick={() => switchEntity(false)}>Previuos</button>
            <button onClick={() => switchEntity(true)}>Next</button>
          </div>
        </section>
      </Match>
      <Match when={$breakpoint()}>
        <section class="flex flex-row w-full h-full   overflow-hidden">
          <div class="flex flex-col w-full h-full">
            <Information entity={entity()} />
          </div>
          <div class="flex flex-col !w-40% h-full space-y-2">
            <Grading
              class="flex flex-col w-full h-full space-y-2 overflow-hidden"
              data={props.data?.value}
              entity={entity()}
              onSubmit={onSubmit}
              onNext={() => switchEntity(true)}
            />
            <div class="flex flex-row w-full h-2rem space-x-2 children:(flex flex-row w-full h-full px-2 py-2 text-0.6rem rounded-lg items-center justify-center cursor-pointer bg#p text#page)">
              <button onClick={() => switchEntity(false)}>Previuos</button>
              <button onClick={() => switchEntity(true)}>Next</button>
            </div>
          </div>
        </section>
      </Match>
    </Switch>
  );
}
